const state = {};

const mutations = {};

const getters = {};

const actions = {
  SEEN_MAIL: ({ getters, dispatch }, mail) => {
    if (!mail) return;
    // if (mail.sender == getters.userInfo.nickName) return;
    if (mail.seen && mail.seen.includes(getters.userInfo.nickName)) return;

    mail.seen = [getters.userInfo.nickName];

    dispatch('UPDATE_MAIL', {
      operationCode: 0,
      emailObjectId: mail._id,
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
