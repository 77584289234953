import Axios from "axios";

// function getCookie(name) {
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop().split(";").shift();
// }

const axios = Axios.create({
  baseURL: `${process.env.VUE_APP_HASHTAG_URL}`,
});

axios.interceptors.request.use(function (config) {
  const tokenDetails = "Bearer " + JSON.parse(localStorage.getItem("main_site_local_storage") || '{}').accessToken || "";
  config.headers.Authorization = tokenDetails;
  return config;
});

function hashtagPayload(functionName, params) {
  return {
    id: "1",
    method: functionName,
    jsonrpc: "2.0",
    params: params ? params : {},
  };
}

export function getLocalCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export function getUserDomains() {
  const body = hashtagPayload("hashtag.getList");

  return axios.post(`${process.env.VUE_APP_HASHTAG_URL}/`, body);
}

export function getHashTagDemoDetails(email) {
  return Axios.post(`${process.env.VUE_APP_CLIENT_URL}`, { email });
}

export function getHashTagDetails(userEmailList) {
  const body = hashtagPayload("user.getUserInfo", { userEmailList });

  return axios.post(`${process.env.VUE_APP_HASHTAG_URL}/`, body);
}
// export default {
//   getCookie,
//   async getUserDomains() {
//     const body = hashtagPayload('hashtag.getList');
//     return axios.post(`${process.env.VUE_APP_HASHTAG_URL}/`, body);
//   },
//   async getHashTagDetails(userEmailList) {
//     const body = hashtagPayload('user.getUserInfo', { userEmailList });
//     return axios.post(`${process.env.VUE_APP_HASHTAG_URL}/`, body);
//   },
// };
