const mutations = {};

const actions = {
  INITIALIZE_EMPTY_FOLDERS: async ({ getters, dispatch }, userFolders) => {
    if (!userFolders) userFolders = getters.foldersList;
    const folderDetails = getters.userFolderDetails;

    Object.entries(userFolders).forEach((data) => {
      if (!getters.userFolderDetails[data[0]])
        folderDetails[data[0]] = { mails: [], totalCount: 0 };

      dispatch("GET_FOLDER_MAILS", { limit: 50, pageNo: 1, tagName: data[0] });
    });
  },

  MAIL_TO_FOLDER: async ({ getters, commit }, { mail, tagName }) => {
    let index = -1;

    for (let i = 0; i < getters.userFolderDetails[tagName].totalCount; i++) {
      if (
        getters.userFolderDetails[tagName].mails[i].timestamp <= mail.timestamp
      ) {
        index = i;
        break;
      }
    }

    if (index != -1) commit("PUSH_FOLDER", { index, mail, tagName });
    else
      commit("PUSH_FOLDER", {
        index: getters.userFolderDetails[tagName].totalCount,
        mail,
        tagName,
      });
  },

  MAIL_TO_ARCHIVE: async ({ getters, commit }, mail) => {
    let index = -1;

    for (let i = 0; i < getters.getArchive.length; i++) {
      if (getters.getArchive[i].timestamp <= mail.timestamp) {
        index = i;
        break;
      }
    }

    if (index != -1) commit("PUSH_ARCHIVE", { index, mail });
    else commit("PUSH_ARCHIVE", { index: getters.getArchive.length, mail });
  },

  REMOVE_FROM_ARCHIVE: async ({ getters, commit }, mail) => {
    let index = 0;

    if (getters.userInfo.nickName == mail.sender) {
      for (let i = 0; i < getters.getSent.length; i++) {
        index = i;
        if (getters.getSent[i].timestamp <= mail.timestamp) break;
      }

      commit("PUSH_SENT", { index, mail });
    } else {
      for (let i = 0; i < getters.getInbox.length; i++) {
        index = i;
        if (getters.getInbox[i].timestamp <= mail.timestamp) break;
      }
      commit("PUSH_INBOX", { index, mail });
    }
  },
};

export default {
  mutations,
  actions,
};
