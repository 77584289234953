import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import * as ledgermailJsLib from "./plugins/ledgermail-jslib";

Vue.config.productionTip = false;
Vue.prototype.$ledgermail_v2 = ledgermailJsLib;

Vue.use(Toast, {
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
  position: POSITION.TOP_CENTER,
  timeout: 5000,
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
