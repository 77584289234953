export default {
  path: "/auth",
  component: () => import("../../views/auth/AuthContainer.vue"),
  redirect: "/auth/login",
  children: [
    {
      path: "/auth/login",
      component: () => import("../../views/auth/SignIn.vue"),
      meta: {
        title: "Sign in to LedgerMail",
      },
      name: "sign in",
    },
    {
      path: "/auth/signup",
      component: () => import("../../views/auth/Signup.vue"),
      meta: {
        title: "Create Account",
      },
      name: "signup",
    },
  ],
  meta: {
    noUserAvailable: true,
  },
};
