import * as axios from "../axios";
import * as ledgermail_v2 from "../ledgermail-jslib";

const request = require("request");
const ObjectID = require("bson").ObjectID;

async function verifyAndDeleteFolder(secret, txBody) {
  const signature = await ledgermail_v2.verifyTransaction(txBody, secret);
  const reqData = {
    type: "DELETE_USER_FOLDER",
    body: txBody,
    signature: signature,
    now: Date.now(),
  };

  const baseReqTxData = Buffer.from(JSON.stringify(reqData)).toString("base64");
  let headers = {
    "Content-Type": "text/plain",
    Accept: "application/json-rpc",
  };
  let options = {
    url: `${process.env.VUE_APP_BASE_URL_CORE}`,
    method: "POST",
    headers: headers,
    json: true,
    body: {
      jsonrpc: "2.0",
      id: "ledgermail-tx",
      method: "broadcast_tx_commit",
      params: { tx: baseReqTxData },
    },
  };
  return new Promise((resolve, reject) => {
    request(options, (error, response, body) => {
      if (error) reject(error);
      resolve({ body, response });
    });
  });
}
async function verifyAndRenameFolder(secret, txBody) {
  const signature = await ledgermail_v2.verifyTransaction(txBody, secret);
  const reqData = {
    type: "UPDATE_USER_FOLDER",
    body: txBody,
    signature: signature,
    now: Date.now(),
  };

  const baseReqTxData = Buffer.from(JSON.stringify(reqData)).toString("base64");
  let headers = {
    "Content-Type": "text/plain",
    Accept: "application/json-rpc",
  };
  let options = {
    url: `${process.env.VUE_APP_BASE_URL_CORE}`,
    method: "POST",
    headers: headers,
    json: true,
    body: {
      jsonrpc: "2.0",
      id: "ledgermail-tx",
      method: "broadcast_tx_commit",
      params: { tx: baseReqTxData },
    },
  };
  return new Promise((resolve, reject) => {
    request(options, (error, response, body) => {
      if (error) reject(error);
      resolve({ body, response });
    });
  });
}

async function verifyAndCreateFolder(secret, txBody) {
  const signature = await ledgermail_v2.verifyTransaction(txBody, secret);
  const reqData = {
    type: "ADD_USER_FOLDER",
    body: txBody,
    signature: signature,
    now: Date.now(),
  };

  const baseReqTxData = Buffer.from(JSON.stringify(reqData)).toString("base64");
  let headers = {
    "Content-Type": "text/plain",
    Accept: "application/json-rpc",
  };
  let options = {
    url: `${process.env.VUE_APP_BASE_URL_CORE}`,
    method: "POST",
    headers: headers,
    json: true,
    body: {
      jsonrpc: "2.0",
      id: "ledgermail-tx",
      method: "broadcast_tx_commit",
      params: { tx: baseReqTxData },
    },
  };
  return new Promise((resolve, reject) => {
    request(options, (error, response, body) => {
      if (error) reject(error);
      resolve({ body, response });
    });
  });
}
async function verifyAndMoveToFolder(secret, txBody) {
  const signature = await ledgermail_v2.verifyTransaction(txBody, secret);
  const reqData = {
    type: "UPDATE_MAIL_FOLDER",
    body: txBody,
    signature: signature,
    now: Date.now(),
  };

  const baseReqTxData = Buffer.from(JSON.stringify(reqData)).toString("base64");
  let headers = {
    "Content-Type": "text/plain",
    Accept: "application/json-rpc",
  };
  let options = {
    url: `${process.env.VUE_APP_BASE_URL_CORE}`,
    method: "POST",
    headers: headers,
    json: true,
    body: {
      jsonrpc: "2.0",
      id: "ledgermail-tx",
      method: "broadcast_tx_commit",
      params: { tx: baseReqTxData },
    },
  };
  return new Promise((resolve, reject) => {
    request(options, (error, response, body) => {
      if (error) reject(error);
      resolve({ body, response });
    });
  });
}

async function verifyAndCreateMail(secret, txBody) {
  const signature = await ledgermail_v2.verifyTransaction(txBody, secret);
  const reqData = {
    type: "CREATE_MAIL",
    body: txBody,
    signature: signature,
    now: Date.now(),
  };

  const baseReqTxData = Buffer.from(JSON.stringify(reqData)).toString("base64");
  let headers = {
    "Content-Type": "text/plain",
    Accept: "application/json-rpc",
  };
  let options = {
    url: `${process.env.VUE_APP_BASE_URL_CORE}`,
    method: "POST",
    headers: headers,
    json: true,
    body: {
      jsonrpc: "2.0",
      id: "ledgermail-tx",
      method: "broadcast_tx_commit",
      params: { tx: baseReqTxData },
    },
  };
  return new Promise((resolve, reject) => {
    request(options, (error, response, body) => {
      if (error) reject(error);
      resolve({ body, response });
    });
  });
}

async function verifyAndUpdateMail(secret, txBody) {
  const signature = await ledgermail_v2.verifyTransaction(txBody, secret);
  const reqData = {
    type: "UPDATE_MAIL",
    body: txBody,
    signature: signature,
    now: Date.now(),
  };

  const baseReqTxData = Buffer.from(JSON.stringify(reqData)).toString("base64");
  let headers = {
    "Content-Type": "text/plain",
    Accept: "application/json-rpc",
  };
  let options = {
    url: `${process.env.VUE_APP_BASE_URL_CORE}`,
    method: "POST",
    headers: headers,
    json: true,
    body: {
      jsonrpc: "2.0",
      id: "ledgermail-tx",
      method: "broadcast_tx_commit",
      params: { tx: baseReqTxData },
    },
  };
  return new Promise((resolve, reject) => {
    request(options, (error, response, body) => {
      if (error) reject(error);
      resolve({ body, response });
    });
  });
}

export default {
  async createFolder(privateKey, { nickName, folderName, folderTag }) {
    const data = {
      nickName,
      folderName,
      folderTag,
    };
    const base64Data = Buffer.from(JSON.stringify(data)).toString("base64");

    return await verifyAndCreateFolder(privateKey, base64Data)
      .then((res) => {
        return getProcessedBroadcastResponse(res.body);
      })
      .catch((err) => {
        console.error("folder Error", err);
        return {
          success: false,
          message: "Error #501, Try again after some time!",
        };
      });
  },
  async renameFolder(privateKey, { nickName, folderName, folderTag }) {
    const data = {
      nickName,
      folderName,
      folderTag,
    };
    const base64Data = Buffer.from(JSON.stringify(data)).toString("base64");

    return await verifyAndRenameFolder(privateKey, base64Data)
      .then((res) => {
        return getProcessedBroadcastResponse(res.body);
      })
      .catch((err) => {
        console.error("folder Error", err);
        return {
          success: false,
          message: "Error #501, Try again after some time!",
        };
      });
  },

  async moveToFolder(privateKey, { nickName, emailObjectId, folderTag }) {
    const data = {
      nickName,
      emailObjectId,
      folderTag,
    };
    const base64Data = Buffer.from(JSON.stringify(data)).toString("base64");

    return await verifyAndMoveToFolder(privateKey, base64Data)
      .then((res) => {
        return getProcessedBroadcastResponse(res.body);
      })
      .catch((err) => {
        console.error("folder Error", err);
        return {
          success: false,
          message: "Error #501, Try again after some time!",
        };
      });
  },
  async deleteFolder(privateKey, { nickName, folderTag }) {
    const data = { nickName, folderTag };
    const base64Data = Buffer.from(JSON.stringify(data)).toString("base64");

    return await verifyAndDeleteFolder(privateKey, base64Data)
      .then((res) => {
        return getProcessedBroadcastResponse(res.body);
      })
      .catch((err) => {
        console.error("folder Error", err);
        return {
          success: false,
          message: "Error #501, Try again after some time!",
        };
      });
  },

  async replyMail(
    privateKey,
    {
      sender,
      receiver,
      subject,
      body,
      attachments,
      cc,
      secrets,
      parentId,
      domainHolders,
    }
  ) {
    const mailId = new ObjectID().toString();
    const createdAt = new Date().toISOString();

    const data = {
      mailId,
      sender,
      receiver,
      cc,
      subject,
      body,
      attachments,
      mailType: "replyMail",
      secrets,
      parentId,
      domainHolders,
      createdAt,
    };
    const base64Data = Buffer.from(JSON.stringify(data)).toString("base64");
    return await verifyAndCreateMail(privateKey, base64Data)
      .then((res) => {
        return getProcessedBroadcastResponse(res.body, {
          recipients: [...receiver, ...cc],
          mailType: "replyMail",
          parentId: parentId,
          sender,
          domainHolders: data.domainHolders,
        });
      })
      .catch((err) => {
        console.error("Mail Error", err);
        return {
          success: false,
          message: "Error #501, Try again after some time!",
        };
      });
  },

  async forwardMail(
    privateKey,
    {
      sender,
      receiver,
      subject,
      body,
      attachments,
      cc,
      secrets,
      previousMails,
      domainHolders,
    }
  ) {
    const mailId = new ObjectID().toString();
    const createdAt = new Date().toISOString();
    const data = {
      mailId,
      sender,
      receiver,
      cc: cc,
      subject,
      body,
      attachments,
      mailType: "forwardMail",
      secrets,
      parentId: mailId,
      previousMails,
      createdAt,
      domainHolders,
    };
    const base64Data = Buffer.from(JSON.stringify(data)).toString("base64");
    return await verifyAndCreateMail(privateKey, base64Data)
      .then((res) => {
        return getProcessedBroadcastResponse(res.body, {
          recipients: [...receiver, ...cc],
          mailType: "forwardMail",
          parentId: mailId,
          domainHolders: data.domainHolders,
          sender,
        });
      })
      .catch((err) => {
        console.error("Mail Error", err);
        return {
          success: false,
          message: "Error #501, Try again after some time!",
        };
      });
  },

  async updateMail(privateKey, { receiver, operationCode, emailObjectId }) {
    const data = { receiver, operationCode, emailObjectId };
    const base64Data = Buffer.from(JSON.stringify(data)).toString("base64");

    return await verifyAndUpdateMail(privateKey, base64Data)
      .then((res) => getProcessedBroadcastResponse(res.body))
      .catch((err) => {
        console.error("Mail Error", err);
        return {
          success: false,
          message: "Error #501, Try again after some time!",
        };
      });
  },
};

// Helper Function
async function getProcessedBroadcastResponse(response, mailData = null) {
  const res = response.result;

  if (response.error) return { success: false, message: response.error.data };
  else if (res.check_tx.code === 0 && res.deliver_tx.code === 0) {
    if (mailData) axios.sendNotification(mailData);
    return { success: true, response: res };
  } else
    return {
      success: false,
      response: res,
      message: res.check_tx.info || res.deliver_tx.info,
    };
}
