import Vue from "vue";
import VueRouter from "vue-router";

import authRoutes from "./routes/authRoutes";
import dashboardRoutes from "./routes/dashboardRoutes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    authRoutes,
    dashboardRoutes,
    {
      path: "*",
      redirect: "/auth",
    },
  ],
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const noUserAvailable = to.matched.some(
    (record) => record.meta.noUserAvailable
  );

  try {
    const status = localStorage.getItem("access-token");

    if (requiresAuth && !status) next("/auth");
    else if (noUserAvailable && !!status) next("/");
    else next();
  } catch (error) {
    console.error("ROUTER_ERROR", error);
    next("/auth");
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title =
      to.meta.title || "D-Mail | Hashtag (CAPS LOCK) | Ledgermail";
  });
});

export default router;
