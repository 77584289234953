const request = require("request");
const ObjectID = require("bson").ObjectID;

const ledgermail_v2 = require("../ledgermail-jslib");
const {
  getReqOptions,
  getProcessedBroadcastResponse,
} = require("./core-api-helper");

export default {
  async createMail(privateKey, data) {
    const mailId = new ObjectID().toString();
    const createdAt = new Date().toISOString();
    const txBody = Buffer.from(
      JSON.stringify({
        mailId,
        sender: data.sender,
        receiver: data.receiver,
        cc: data.cc,
        subject: data.subject,
        body: data.body,
        attachments: data.attachments,
        mailType: "newMail",
        domainHolders: data.domainHolders,
        secrets: data.secrets,
        createdAt,
        parentId: mailId,
      })
    ).toString("base64");
    const signature = await ledgermail_v2.verifyTransaction(txBody, privateKey);

    return new Promise((resolve, reject) => {
      request(
        getReqOptions("CREATE_MAIL", txBody, signature),
        (error, response, body) => {
          if (!error)
            resolve(
              getProcessedBroadcastResponse(body, {
                recipients: [...data.receiver, ...data.cc],
                mailType: "newMail",
                parentId: mailId,
                domainHolders: data.domainHolders,
                sender: data.sender,
              })
            );
          reject({ message: "Error #501, Try again after some time!" });
        }
      );
    });
  },
};
