const bip39 = require("bip39");
const hdKey = require("hdkey");
const crypto = require("crypto");
const eccrypto = require("eccrypto");
const CryptoJS = require("crypto-js");

// ASEDecrypt
// ASEEncrypt

export function ASEEncrypt(message, secret) {
  return CryptoJS.AES.encrypt(message, secret).toString();
}
export function ASEDecrypt(cipherText, secret) {
  var bytes = CryptoJS.AES.decrypt(cipherText, secret);
  return bytes.toString(CryptoJS.enc.Utf8);
}

export const generateMnemonic = () => bip39.generateMnemonic();

export function getExtPublicKey(mnemonic) {
  const hexSeed = bip39.mnemonicToSeedSync(mnemonic).toString("hex");
  return hdKey.fromMasterSeed(hexSeed).publicExtendedKey;
}

export function getExtPrivateKey(mnemonic) {
  const hexSeed = bip39.mnemonicToSeedSync(mnemonic).toString("hex");
  return hdKey.fromMasterSeed(hexSeed).privateExtendedKey;
}

export function getPublicKey(mnemonic) {
  const seed = bip39.mnemonicToSeedSync(mnemonic);
  return hdKey.fromMasterSeed(seed).publicKey.toString("hex");
}

export function getPrivateKey(mnemonic) {
  const seed = bip39.mnemonicToSeedSync(mnemonic);
  return hdKey.fromMasterSeed(seed).privateKey;
}

export async function verifyTransaction(txBody, secret) {
  const hash = crypto.createHash("sha256").update(txBody).digest();
  return (await eccrypto.sign(secret, hash)).toString("hex");
}

export function encrypt(payload, xPublicKey) {
  const public_key = hdKey.fromExtendedKey(xPublicKey).publicKey;

  return new Promise((resolve, reject) => {
    eccrypto
      .encrypt(public_key, Buffer.from(payload))
      .then((encrypted) => {
        resolve(JSON.stringify(encrypted));
      })
      .catch((err) => reject(err));
  });
}

export function decrypt(payload, xPrivateKey) {
  const private_key = hdKey.fromExtendedKey(xPrivateKey).privateKey;
  const chipper = {
    iv: Buffer.from(payload.iv.data),
    ephemPublicKey: Buffer.from(payload.ephemPublicKey.data),
    ciphertext: Buffer.from(payload.ciphertext.data),
    mac: Buffer.from(payload.mac.data),
  };

  return new Promise((resolve, reject) => {
    eccrypto
      .decrypt(private_key, chipper)
      .then((plaintext) => {
        resolve(plaintext.toString());
      })
      .catch((err) => reject(err));
  });
}
