// import { getHashTagDetails } from "../../plugins/api/hashtag-api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  LOGIN_HANDLER: ({ commit, dispatch }, { signInResponse, mnemonic }) => {
    commit("DUMMY");
    const requestTime = new Date();

    localStorage.setItem("access-token", signInResponse.token);

    /** ************************ **/

    const userFolders = signInResponse.user.folder
      ? signInResponse.user.folder
      : {};

    localStorage.setItem("folders-list", JSON.stringify(userFolders));

    commit("SET_FOLDERS_LIST", userFolders);
    dispatch("INITIALIZE_EMPTY_FOLDERS", userFolders);

    dispatch("GET_PRIVATEKEY", mnemonic);
    /** ************************ **/

    if (signInResponse.tokenExpire == "1d")
      requestTime.setSeconds(requestTime.getSeconds() + 30);

    localStorage.setItem(
      "auth-token",
      JSON.stringify({
        token: signInResponse.token,
        exp: requestTime,
        emailId: signInResponse.user.emailId,
      })
    );

    /** ************************ **/

    signInResponse.user.mnemonic = mnemonic;
    return commit("UPDATE_USER_DETAILS", signInResponse.user);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
