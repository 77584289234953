import { blockHashtagNames, unblockHashtagNames } from "@/plugins/api/user-api";

const state = {
  BlockedUsers: [],
  showBlockedUserModel: false,
  tempBlockUserName: "",
};

const mutations = {
  BLOCKED_USER_POPUP: (state, { status, tempName }) => {
    state.showBlockedUserModel = status;
    state.tempBlockUserName = tempName;
  },
  CLEAR_TEMP_NAME: (state) => {
    state.tempBlockUserName = "";
  },
};

const getters = {
  showBlockedUserModel: (state) => state.showBlockedUserModel,
  tempBlockUserName: (state) => state.tempBlockUserName,
};

const actions = {
  SHOW_BLOCKED_USER_POPUP: ({ commit }, tempName = "") => {
    commit("BLOCKED_USER_POPUP", { status: true, tempName });
  },
  HIDE_BLOCKED_USER_POPUP: ({ commit }) => {
    commit("BLOCKED_USER_POPUP", false);
  },

  BLOCK_HASHTAG_NAME: ({ getters, commit }, { hashtagName }) => {
    let userInfo = getters.userInfo;

    return new Promise((resolve, reject) => {
      if (userInfo.blockedNames.includes(hashtagName))
        return reject("User/Domain is already blocked");

      blockHashtagNames(getters.privateKey, {
        nickName: getters.userInfo.nickName,
        hashtagName,
      })
        .then((result) => {
          commit("DOMAIN_UPDATE_STATUS", false);
          if (!result.success) return reject(result.message);

          userInfo.blockedNames.push(hashtagName);
          commit("UPDATE_USER_DETAILS", userInfo);

          resolve();
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          reject(
            "Error 404: Please check your connection and try again later!"
          );
        });
    });
  },

  UNBLOCK_HASHTAG_NAME: ({ getters, commit }, { hashtagName }) => {
    let userInfo = getters.userInfo;

    return new Promise((resolve, reject) => {
      if (!userInfo.blockedNames.includes(hashtagName))
        return reject("User/Domain has not been blocked");

      unblockHashtagNames(getters.privateKey, {
        nickName: getters.userInfo.nickName,
        hashtagName,
      })
        .then((result) => {
          commit("DOMAIN_UPDATE_STATUS", false);
          if (!result.success) return reject(result.message);

          userInfo.blockedNames.splice(
            userInfo.blockedNames.indexOf(hashtagName),
            1
          );

          commit("UPDATE_USER_DETAILS", userInfo);

          resolve();
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          reject(
            "Error 404: Please check your connection and try again later!"
          );
        });
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
