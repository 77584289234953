import Vue from "vue";
import Vuex from "vuex";

import multiFolder from "./old-modules/multiple-folder-module";
import archive from "./old-modules/archive-module";
import auth from "./old-modules/auth-module";
import mail from "./old-modules/mail-module";
import socket from "./old-modules/socket-module";
import seen from "./old-modules/seen-module";
import names from "./old-modules/names-module";
import crypto from "./old-modules/crypto-module";
import user from "./old-modules/user-modules";
import global from "./old-modules/global-module";
import domain from "./old-modules/domain-module";

import theme from "./modules/theme-module";
import signup from "./modules/user-module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    GLB_VUE: null,
  },
  mutations: {
    GLB_VUE: (state, GLB_VUE) => {
      state.GLB_VUE = GLB_VUE;
    },
    DUMMY: () => true,
  },
  getters: {
    vue: (state) => state.GLB_VUE,
  },
  actions: {
    GLOBAL_FUN: ({ commit, dispatch }, GLB_VUE) => {
      commit("GLB_VUE", GLB_VUE);

      const locTheme = localStorage.getItem("glb_theme");
      if (locTheme) commit(locTheme);
      else commit("DARK_THEME");

      dispatch("GLOBAL_INIT", {
        skipAuthInit: false,
        addMailInit: false,
      });
    },
  },
  modules: {
    mail,
    multiFolder,
    auth,
    socket,
    seen,
    names,
    crypto,
    archive,
    theme,
    signup,
    user,
    global,
    domain,
  },
});
