import { getLocalCookie, getUserDomains } from "@/plugins/api/hashtag-api";
import { addDomains } from "@/plugins/api/user-api";

function difference(setA, setB) {
  setA = new Set(setA);
  setB = new Set(setB);
  let _difference = new Set(setA);

  for (let elem of setB) {
    _difference.delete(elem);
  }

  return Array.from(_difference);
}

const state = {
  hashtagDomains: [],
  isDomainsVerified: false,
  isDomainUpdating: true,
};

const mutations = {
  DOMAIN_UPDATE_STATUS: (state, value) => {
    state.isDomainUpdating = value;
  },
  SET_HASHTAG_DOMAINS: (state, { items }) => {
    state.hashtagDomains = items.map((i) => i.id);
    state.isDomainsVerified = true;
  },
};

const getters = {
  hashtagDomains: (state) => state.hashtagDomains,
  isDomainsVerified: (state) => state.isDomainsVerified,
  isDomainUpdating: (state) => state.isDomainUpdating,
};

const actions = {
  // GET_DOMAINS: async ({ commit }) => {
  //   // console.log(getUserDomains());
  //   /* ********** REMOVE FOR PRODUCTION (start) ********** */
  //   let email = JSON.parse(getLocalCookie("_mh_usr")).email;
  //   let items = [];

  //   if (email === "senthilkumar.subbannan@gmail.com")
  //     items = [{ id: "#l-mail" }];
  //   else if (email === "senthilkumar.subba@gmail.com")
  //     items = [{ id: "#hashtag-mail" }, { id: "#pingala-test" }];
  //   else if (email === "rssenthilkumar88@gmail.com") items = [];
  //   else if (email === "ajayarya512@gmail.com")
  //     items = [
  //       { id: "#ajay-mail" },
  //       { id: "#ledgermail" },
  //       // { id: "#tester" },
  //     ];
  //   else if (email === "ajay@pingalasoftware.com")
  //     items = [
  //       { id: "#pingala-ajay" },
  //       { id: "#ledgermail12" },
  //       { id: "#pingala-tester" },
  //       // { id: '#pingala-test-ajay' },
  //     ];
  //   else if (email === "pingalatestuser1@gmail.com")
  //     items = [
  //       { id: "#test-domain-1" },
  //       { id: "#domain-1-2" },
  //       { id: "#website-1-3" },
  //     ];
  //   else if (email === "pingalatestuser2@gmail.com") {
  //     items = [
  //       { id: "#test-domain-2" },
  //       { id: "#domain-2-2" },
  //       { id: "#website-2-3" },
  //       // { id: '#pingala-test-ajay' },
  //     ];
  //   } else if (email === "nagnath602@gmail.com")
  //     items = [{ id: "#test-domain-3" }, { id: "#website-3-3" }];
  //   else if (email === "pingalasskt1@gmail.com")
  //     items = [{ id: "#website-sskt-1" }];

  //   return commit("SET_HASHTAG_DOMAINS", { items });
  //   /* ********** REMOVE FOR PRODUCTION (end) ********** */
  // },
  GET_DOMAINS: async ({ getters, commit }) => {
    // if (process.env.NODE_ENV === 'development')
    //   return console.log('DEVELOPMENT');
    if (getters.isDomainsVerified) return;

    const { data } = await getUserDomains();
    if (data.result) return commit("SET_HASHTAG_DOMAINS", data.result);

    // If token not found or got any error (token expired) redirect to hashtag.space
    getters.vue.$toast.error("Please open the D-Mail from Hashtag-Dashboard");
    setTimeout(() => {
      window.location.href = "https://hashtag.space/domainsdashboard/";
    }, 1000);

    return;
  },
  VERIFY_USR_IMAGE: async ({ getters }) => {
    // console.info("getters.userInfo", getters.userInfo);
    const userCookie = JSON.parse(getLocalCookie("_mh_usr") || "{}");

    if (!getters.userInfo) return;

    if (!userCookie) return;
    if (!userCookie.img || userCookie.img == "")
      return console.info("User image not found");

    if (userCookie.img == getters.userInfo.img)
      return console.info("User image exists");
  },
  VERIFY_DOMAINS: async ({ getters, commit, dispatch }) => {
    if (!getters.userInfo.nickName) return;

    await dispatch("GET_DOMAINS");

    const hashtagDomains = getters.hashtagDomains;
    const storedDomains = getters.userInfo.domains || [];

    if (!getters.privateKey.length) await dispatch("GET_PRIVATEKEY");
    const expiredDomains = difference(storedDomains, hashtagDomains);
    let newDomains = difference(hashtagDomains, storedDomains);

    if (!newDomains.length && !expiredDomains.length) return;

    if (hashtagDomains == storedDomains) return;

    commit("DOMAIN_UPDATE_STATUS", true);

    addDomains(getters.privateKey, {
      nickName: getters.userInfo.nickName,
      expiredDomains,
      // newDomains,
      newDomains: hashtagDomains,
    }).then((result) => {
      commit("DOMAIN_UPDATE_STATUS", false);
      if (!result.success) return;

      const userDetails = getters.userInfo;
      // userDetails.domains = newDomains;
      userDetails.domains = hashtagDomains;

      commit("UPDATE_USER_DETAILS", userDetails);
    });
  },
};

export default { actions, state, mutations, getters };
