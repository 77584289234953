import sendMailApi from "@/plugins/api/send-mail.js";
import * as axios from "@/plugins/axios.js";
import { getPrivateKey } from "@/plugins/ledgermail-jslib";
import coreApi from "@/plugins/old-api/core-api.js";
import router from "@/router";

const state = {
  mailStatus: "pending",
  inboxMails: [],
  sentMails: [],
  archiveMails: [],
  dummy: true,
  inboxTotalMails: 0,
  sentTotalMails: 0,
  archiveTotalMails: 0,
  userFolderDetails: {},

  inboxSelectedNames: [],
  sentSelectedNames: [],
  archiveSelectedNames: [],
};

const mutations = {
  UPDATE_EXISTING_FIELD: (state, value) => {
    if (router.currentRoute.name === "inbox")
      state.inboxMails[value.index] = { ...value.data }
    if (router.currentRoute.name === "sent")
      state.sentMails[value.index] = { ...value.data }
    if (router.currentRoute.name === "archive")
      state.archiveMails[value.index] = { ...value.data }


    // if (router.currentRoute.name === "user folders")

  },
  CHANGE_INBOX_SELECTED_NAME: (state, value) => {
    state.inboxSelectedNames = value;
  },
  CHANGE_SENT_SELECTED_NAME: (state, value) => {
    state.sentSelectedNames = value;
  },
  CHANGE_ARCHIVE_SELECTED_NAME: (state, value) => {
    state.archiveSelectedNames = value;
  },

  INIT_MAIL_REQUEST: (state) => {
    state.mailStatus = "loading";
  },
  INIT_MAIL_SUCCESS: () => {
    state.mailStatus = "success";
  },

  REPLACE_INBOX_MAILS: (state, { allInboxMails }) => {
    state.inboxMails = allInboxMails;
  },
  PUSH_INBOX: (state, { index, mail }) => {
    state.inboxMails.splice(index, 0, mail);
    state.inboxTotalMails += 1;
  },
  PUSH_SENT: (state, { index, mail }) => {
    state.sentMails.splice(index, 0, mail);
    state.sentTotalMails += 1;
  },

  INIT_INBOX_MAIL: (state, { data }) => {
    state.inboxMails = data.mails;
    state.inboxTotalMails = data.totalCount[0] ? data.totalCount[0].count : 0;
  },
  INIT_SENT_MAIL: (state, { data }) => {
    state.sentMails = data.mails;
    state.sentTotalMails = data.totalCount[0] ? data.totalCount[0].count : 0;
  },
  INIT_ARCHIVE_MAIL: (state, { data }) => {
    state.archiveMails = data.mails;
    state.archiveTotalMails = data.totalCount[0] ? data.totalCount[0].count : 0;
  },

  NEW_REPLY_SOCKET: (state, { mail, index }) => {
    state.inboxMails.splice(index, 1);
    state.inboxMails = [mail, ...state.inboxMails];
  },
  NEW_INBOX_SOCKET: (state, { newMails, totalCount }) => {
    state.inboxMails = [...newMails, ...state.inboxMails];
    state.inboxTotalMails = totalCount;
  },

  RESET_INBOX: (state, { newMails, length, totalCount }) => {
    const oldMails = state.inboxMails.splice(length);
    state.inboxMails = [...newMails, ...oldMails];
    state.inboxTotalMails = totalCount;
  },

  UPDATE_TOTAL_INBOX: (state, value) => {
    state.inboxTotalMails += value;
  },
  UPDATE_TOTAL_SENT: (state, value) => {
    state.sentTotalMails += value;
  },
  UPDATE_TOTAL_ARCHIVE: (state, value) => {
    state.archiveTotalMails += value;
  },

  UPDATE_INBOX: (state, { data, replace }) => {
    if (replace) {
      state.inboxMails = data.mails;
      state.inboxTotalMails = data.totalCount[0] ? data.totalCount[0].count : 0;
    } else {
      state.inboxMails = state.inboxMails.concat(data.mails);
      state.inboxTotalMails = data.totalCount[0] ? data.totalCount[0].count : 0;
    }
  },
  UPDATE_SENT: (state, { data, skipValidation, isReply, replace }) => {
    if (isReply) {
      if (!state.sentTotalMails) {
        state.sentMails = data.mails;
        state.sentTotalMails = 1;
        state.mailStatus = "success";
        return;
      }

      let index = data.mails.findIndex(
        (mail) => mail.parentId == state.sentMails[0].parentId
      );
      const newReplyMails = data.mails.slice(0, index);

      for (let newIndex = 0; newIndex < newReplyMails.length; newIndex++) {
        let index = state.sentMails.findIndex(
          (mail) => mail.parentId == newReplyMails[newIndex].parentId
        );
        if (index > -1) state.sentMails.splice(index, 1);
      }

      state.sentMails = [...newReplyMails, ...state.sentMails];
      state.sentTotalMails = data.totalCount[0] ? data.totalCount[0].count : 0;
    } else if (skipValidation) {
      const diff = data.totalCount[0]
        ? data.totalCount[0].count - state.sentTotalMails
        : 0 - state.sentTotalMails;
      if (diff <= 0) return (state.mailStatus = "success");

      state.sentMails = [...data.mails.slice(0, diff), ...state.sentMails];
      state.sentTotalMails = data.totalCount[0] ? data.totalCount[0].count : 0;
    } else if (replace) {
      state.sentMails = data.mails;
      state.sentTotalMails = data.totalCount[0] ? data.totalCount[0].count : 0;
    } else {
      state.sentMails = [...state.sentMails, ...data.mails];
      state.sentTotalMails = data.totalCount[0] ? data.totalCount[0].count : 0;
    }
    state.mailStatus = "success";
  },
  // UPDATE_SENT: (state, { data }) => {
  //   state.sentMails = state.sentMails.concat(data.mails);
  //   state.sentTotalMails = data.totalCount[0].count;
  // },

  PUSH_ARCHIVE: (state, { index, mail }) => {
    state.archiveTotalMails += 1;
    state.archiveMails.splice(index, 0, mail);
  },
  PUSH_FOLDER: (state, { index, mail, tagName }) => {
    state.userFolderDetails[tagName].totalCount += 1;
    state.userFolderDetails[tagName].mails.splice(index, 0, mail);
  },

  UPDATE_ARCHIVE: (state, { data }) => {
    if (data.skip > state.archiveMails.length)
      state.archiveMails = state.archiveMails.concat(data.mails);
    else if (state.archiveMails.length == data.mails.length) return;
    else state.archiveMails = data.mails;
    state.archiveTotalMails = data.totalCount[0] ? data.totalCount[0].count : 0;
  },

  UPDATE_SINGLE_FOLDER: (state, { data, replace }) => {
    if (!state.userFolderDetails[data.tagName])
      state.userFolderDetails[data.tagName] = { mails: [] };

    if (replace) {
      state.userFolderDetails[data.tagName].mails = data.mails;
    } else {
      state.userFolderDetails[data.tagName].mails = state.userFolderDetails[
        data.tagName
      ].mails.concat(data.mails);
      state.inboxTotalMails = data.totalCount[0] ? data.totalCount[0].count : 0;
    }
    // if (data.skip > state.userFolderDetails[data.tagName].mails.length)
    //   state.userFolderDetails[data.tagName].mails = state.userFolderDetails[
    //     data.tagName
    //   ].mails.concat(data.mails);
    // else if (
    //   state.userFolderDetails[data.tagName].mails.length == data.mails.length
    // )
    //   return;
    // else state.userFolderDetails[data.tagName].mails = data.mails;

    state.userFolderDetails[data.tagName].totalCount = data.totalCount[0]
      ? data.totalCount[0].count
      : 0;
  },

  INIT_MAIL_ERROR: (state) => {
    state.mailStatus = "error";
  },
  RESET_MAIL_STAGE: (state) => {
    state.mailStatus = "pending";
    state.inboxMails = [];
    state.sentMails = [];
    state.dummy = true;
  },
};

const getters = {
  initMailStatus: (state) => state.mailStatus,
  getInbox: (state) => state.inboxMails,
  getArchive: (state) => state.archiveMails,
  getSent: (state) => state.sentMails,
  getInboxTotalMails: (state) => state.inboxTotalMails,
  getSentTotalMails: (state) => state.sentTotalMails,
  getArchiveTotalMails: (state) => state.archiveTotalMails,

  isFirstInbox: (state) => state.isFirstInbox,
  isFirstSent: (state) => state.isFirstSent,

  userFolderDetails: (state) => state.userFolderDetails,

  inboxSelectedNames: (state) => state.inboxSelectedNames,
  sentSelectedNames: (state) => state.sentSelectedNames,
  archiveSelectedNames: (state) => state.archiveSelectedNames,
};

const actions = {
  GET_REPLY_MAILS: ({ getters }, body) => {
    return new Promise((resolve, reject) => {
      axios
        .getReplyMails(getters.userInfo.nickName, body.id, body.parentId)
        .then(({ data }) => {
          if (data.error) reject(data.message);
          else resolve(data.mails);
        })
        .catch((error) => reject(error));
    });
  },
  MAIL_INIT: ({ getters, commit, dispatch }) => {
    if (!localStorage.getItem("auth-token")) return;

    commit("INIT_MAIL_REQUEST");

    return new Promise((resolve, reject) => {
      try {
        const inboxMails = axios.getFilteredInbox(getters.userInfo.nickName);
        const sentMails = axios.getFilteredSent(getters.userInfo.nickName);
        axios.getFilteredArchive(getters.userInfo.nickName).then(({ data }) => {
          commit("INIT_ARCHIVE_MAIL", { data });
        });

        Promise.all([inboxMails, sentMails])
          .then((data) => {
            commit("INIT_INBOX_MAIL", { data: data[0].data });
            commit("INIT_SENT_MAIL", { data: data[1].data });

            let checkArray = [...data[0].data.mails, ...data[1].data.mails];
            dispatch("STORE_USER_NAMES", checkArray);

            commit("INIT_MAIL_SUCCESS");
            resolve();
          })
          .catch((error) => {
            commit("INIT_MAIL_ERROR");
            console.error(error);
            if (
              error.response &&
              error.response.data &&
              error.response.data.error
            ) {
              getters.vue.$toast.error(error.response.data.error);
              // dispatch("AUTH_LOGOUT").then(() => {
              //   router.push("/auth/login");
              // });
            }
            reject(error);
          });
      } catch (error) {
        commit("INIT_MAIL_ERROR");
        reject(error);
      }
    });
  },

  GET_ARCHIVE_MAILS: ({ getters, commit, dispatch }, { pageNo, limit }) => {
    if (getters.getArchive.length > (pageNo - 1) * limit) return;

    return new Promise((resolve, reject) => {
      axios
        .getArchive(getters.userInfo.nickName, pageNo, limit)
        .then(({ data }) => {
          commit("UPDATE_ARCHIVE", { data });
          dispatch("STORE_USER_NAMES", data.mails);
          resolve();
        })
        .catch((error) => {
          commit("INIT_MAIL_ERROR");
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          )
            getters.vue.$toast.error(error.response.data.error);
          reject(error);
        });
    });
  },

  GET_FOLDER_MAILS: (
    { getters, commit, dispatch },
    { pageNo, limit, tagName, force }
  ) => {
    if (
      !force &&
      getters.userFolderDetails[tagName] &&
      getters.userFolderDetails[tagName].mails.length > (pageNo - 1) * limit
    )
      return;

    return new Promise((resolve, reject) => {
      axios
        .getSingleFolder(getters.userInfo.nickName, pageNo, limit, tagName)
        .then(({ data }) => {
          commit("UPDATE_SINGLE_FOLDER", {
            data,
            replace: pageNo == 1 ? true : false,
          });
          dispatch("STORE_USER_NAMES", data.mails);
          resolve();
        })
        .catch((error) => {
          commit("INIT_MAIL_ERROR");
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          )
            getters.vue.$toast.error(error.response.data.error);
          reject(error);
        });
    });
  },

  GET_INBOX_MAILS: ({ getters, commit, dispatch }, { pageNo, limit }) => {
    if (getters.getInbox.length > (pageNo - 1) * limit) return;

    return new Promise((resolve, reject) => {
      axios
        .getInbox(getters.userInfo.nickName, pageNo, limit)
        .then(({ data }) => {
          commit("UPDATE_INBOX", { data });
          dispatch("STORE_USER_NAMES", data.mails);

          resolve();
        })
        .catch((error) => {
          commit("INIT_MAIL_ERROR");
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          )
            getters.vue.$toast.error(error.response.data.error);
          reject(error);
        });
    });
  },

  GET_SENT_MAILS: (
    { getters, commit, dispatch },
    { pageNo, limit, skipValidation, isReply }
  ) => {
    // if (getters.getSent.length > (pageNo - 1) * limit) return;
    if (skipValidation || isReply) {
      pageNo = pageNo || 1;
      limit = limit || 50;
    } else if (getters.getSent.length > (pageNo - 1) * limit) {
      return;
    }

    return new Promise((resolve, reject) => {
      axios
        .getSent(getters.userInfo.nickName, pageNo, limit)
        .then(({ data }) => {
          commit("UPDATE_SENT", { data: data, skipValidation, isReply });
          dispatch("STORE_USER_NAMES", data.mails);

          // commit("UPDATE_SENT", { data });
          resolve();
        })
        .catch((error) => {
          commit("INIT_MAIL_ERROR");
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          )
            getters.vue.$toast.error(error.response.data.error);
          reject(error);
        });
    });
  },

  // REFRESH_MAILS: async ({ getters, commit, dispatch }, type) => {
  //   if (type == "INBOX")
  //     axios
  //       .getInbox(getters.userInfo.nickName)
  //       .then(({ data }) => {
  //         commit("INIT_INBOX_MAIL", { data });
  //       })
  //       .catch(() => {
  //         dispatch("MAIL_INIT");
  //       });
  //   else if (type == "SENT")
  //     axios
  //       .getSent(getters.userInfo.nickName)
  //       .then(({ data }) => {
  //         commit("INIT_SENT_MAIL", { data });
  //       })
  //       .catch(() => {
  //         dispatch("MAIL_INIT");
  //       });
  //   else if (type == "ARCHIVE")
  //     axios
  //       .getArchive(getters.userInfo.nickName)
  //       .then(({ data }) => {
  //         commit("INIT_ARCHIVE_MAIL", { data });
  //       })
  //       .catch(() => {
  //         dispatch("MAIL_INIT");
  //       });
  //   else if (type == "ALL") dispatch("MAIL_INIT");
  // },

  COMPOSE_MAIL: async (
    { getters },
    { sender, receiver, subject, body, attachments, cc, secrets, domainHolders }
  ) => {
    const privateKey = await getPrivateKey(getters.mnemonic);

    return new Promise((resolve, reject) => {
      sendMailApi
        .createMail(privateKey, {
          sender,
          receiver,
          subject,
          body,
          attachments,
          cc,
          secrets,
          domainHolders,
        })
        .then((result) => {
          if (result.success) resolve(result);
          else reject(result.message);
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          reject(
            "Error 404: Please check your connection and try again later!"
          );
        });
    });
  },
  REPLY_MAIL: async (
    { getters },
    {
      sender,
      receiver,
      cc,
      subject,
      body,
      attachments,
      secrets,
      parentId,
      domainHolders,
    }
  ) => {
    const privateKey = getters.privateKey
      ? getters.privateKey
      : await getPrivateKey(getters.mnemonic);

    return new Promise((resolve, reject) => {
      coreApi
        .replyMail(privateKey, {
          sender,
          receiver,
          cc,
          subject,
          body,
          attachments,
          secrets,
          parentId,
          domainHolders,
        })
        .then((result) => {
          if (result.success) resolve(result);
          else reject(result.message);
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          reject(
            "Error 404: Please check your connection and try again later!"
          );
        });
    });
  },
  FORWARD_MAIL: async (
    { getters },
    {
      sender,
      receiver,
      subject,
      body,
      attachments,
      cc,
      secrets,
      previousMails,
      domainHolders,
    }
  ) => {
    const privateKey = await getPrivateKey(getters.mnemonic);

    return new Promise((resolve, reject) => {
      coreApi
        .forwardMail(privateKey, {
          sender,
          receiver,
          subject,
          body,
          attachments,
          cc,
          secrets,
          previousMails,
          domainHolders,
        })
        .then((result) => {
          if (result.success) resolve(result);
          else reject(result.message);
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          reject(
            "Error 404: Please check your connection and try again later!"
          );
        });
    });
  },

  UPDATE_MAIL: async ({ getters }, { operationCode, emailObjectId }) => {
    const privateKey = await getPrivateKey(getters.mnemonic);

    return new Promise((resolve, reject) => {
      coreApi
        .updateMail(privateKey, {
          receiver: getters.userInfo.nickName,
          operationCode,
          emailObjectId,
        })
        .then((result) => {
          if (result.success) resolve(result);
          else reject(result.message);
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          reject(
            "Error 404: Please check your connection and try again later!"
          );
        });
    });
  },

  GET_FILTERED_INBOX_MAILS: (
    { getters, commit, dispatch },
    { pageNo, limit, selectedNames }
  ) => {
    if (
      (!selectedNames || selectedNames === getters.inboxSelectedNames) &&
      getters.getInbox.length > (pageNo - 1) * limit
    )
      return;

    commit("CHANGE_INBOX_SELECTED_NAME", selectedNames);

    return new Promise((resolve, reject) => {
      axios
        .getFilteredInbox(
          getters.userInfo.nickName,
          selectedNames,
          pageNo,
          limit
        )
        .then(({ data }) => {
          commit("UPDATE_INBOX", { data, replace: pageNo == 1 ? true : false });
          dispatch("STORE_USER_NAMES", data.mails);

          resolve();
        })
        .catch((error) => {
          commit("INIT_MAIL_ERROR");
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          )
            getters.vue.$toast.error(error.response.data.error);
          reject(error);
        });
    });
  },

  GET_FILTERED_SENT_MAILS: (
    { getters, commit, dispatch },
    { pageNo, limit, skipValidation, isReply, selectedNames }
  ) => {
    // if (getters.getSent.length > (pageNo - 1) * limit) return;

    if (skipValidation || isReply) {
      pageNo = pageNo || 1;
      limit = limit || 50;
    } else if (
      (!selectedNames || selectedNames === getters.sentSelectedNames) &&
      getters.getSent.length > (pageNo - 1) * limit
    )
      return;

    return new Promise((resolve, reject) => {
      axios
        .getFilteredSent(
          getters.userInfo.nickName,
          selectedNames,
          pageNo,
          limit
        )
        .then(({ data }) => {
          commit("UPDATE_SENT", {
            data: data,
            skipValidation,
            isReply,
            replace: pageNo == 1 ? true : false,
          });
          dispatch("STORE_USER_NAMES", data.mails);

          // commit("UPDATE_SENT", { data });
          resolve();
        })
        .catch((error) => {
          commit("INIT_MAIL_ERROR");
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          )
            getters.vue.$toast.error(error.response.data.error);
          reject(error);
        });
    });
  },

  GET_FILTERED_ARCHIVE_MAILS: (
    { getters, commit, dispatch },
    { pageNo, limit, selectedNames }
  ) => {
    if (
      (!selectedNames || selectedNames === getters.sentSelectedNames) &&
      getters.getArchive.length > (pageNo - 1) * limit
    )
      return;

    return new Promise((resolve, reject) => {
      axios
        .getFilteredArchive(
          getters.userInfo.nickName,
          selectedNames,
          pageNo,
          limit
        )
        .then(({ data }) => {
          commit("UPDATE_ARCHIVE", { data });
          dispatch("STORE_USER_NAMES", data.mails);

          resolve();
        })
        .catch((error) => {
          commit("INIT_MAIL_ERROR");
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          )
            getters.vue.$toast.error(error.response.data.error);
          reject(error);
        });
    });
  },

  REFRESH_MAILS: async ({ getters, commit, dispatch }, type) => {
    return new Promise((resolve) => {
      if (type == "INBOX")
        axios
          .getFilteredInbox(getters.userInfo.nickName)
          .then(({ data }) => {
            commit("INIT_INBOX_MAIL", { data });
            dispatch("STORE_USER_NAMES", data.mails);

            resolve();
          })
          .catch(() => {
            dispatch("MAIL_INIT");
            resolve();
          });
      else if (type == "SENT")
        axios
          .getFilteredSent(getters.userInfo.nickName)
          .then(({ data }) => {
            commit("INIT_SENT_MAIL", { data });
            dispatch("STORE_USER_NAMES", data.mails);
            resolve();
          })
          .catch(() => {
            dispatch("MAIL_INIT");
            resolve();
          });
      else if (type == "ARCHIVE")
        axios
          .getFilteredArchive(getters.userInfo.nickName)
          .then(({ data }) => {
            commit("INIT_ARCHIVE_MAIL", { data });
            dispatch("STORE_USER_NAMES", data.mails);
            resolve();
          })
          .catch(() => {
            dispatch("MAIL_INIT");
            resolve();
          });
      else if (type == "ALL") {
        dispatch("MAIL_INIT");
        resolve();
      }
    });
  },
};

export default { state, mutations, getters, actions };
