import Axios from "axios";

const axios = Axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL_SERVER}/api`,
});
const chat = Axios.create({
  baseURL: `${process.env.VUE_APP_NOTIFICATION}/chat`,
});

export function sendNotification({
  recipients,
  mailType,
  parentId,
  sender,
  domainHolders,
}) {
  const headers = { authentication: localStorage.getItem("access-token") };

  return chat.post(
    `/new-mail`,
    { recipients, mailType, parentId, sender, domainHolders },
    { headers }
  );
}

export function getUserDetails(hashtagNames) {
  const headers = { authentication: localStorage.getItem("access-token") };

  return axios.post(`/ledgermail/user-details`, { hashtagNames }, { headers });
}

export function getHashTagDetails(emailIds) {
  const body = {
    id: "1",
    method: "user.getUserInfo",
    jsonrpc: "2.0",
    params: { userEmailList: emailIds },
  };

  return Axios.post(`${process.env.VUE_APP_CLIENT_URL}`, body);
}

export function getSignature(type, body, emailId, img, nickName, role) {
  const nBody = { type, body, emailId, img, nickName, role };
  return axios.post(`/auth/signature`, nBody);
}

export function login(publicKey, nickName) {
  return axios.post(`/auth/login`, { publicKey, nickName });
}

export function verifyLogin(nickName) {
  const headers = {
    authentication: localStorage.getItem("access-token") || "",
  };
  return axios.post(`/ledgermail/verify-login`, { nickName }, { headers });
}

export function verifyEmail(emailId, publicKey) {
  // const headers = {
  //   authentication: localStorage.getItem("access-token") || "",
  // };

  return axios.get(
    `/auth/verify-account?emailId=${emailId}&publicKey=${publicKey}`
  );
}

export function getPublicKeys(nickNames) {
  const headers = {
    authentication: localStorage.getItem("access-token") || "",
  };
  return axios.post(`/ledgermail/public-keys`, nickNames, { headers });
}

export function getInbox(nickName, pageNo = 1, limit = 50) {
  const headers = {
    authentication: localStorage.getItem("access-token") || "",
  };
  return axios.post(
    `/ledgermail/inbox`,
    { nickName, limit, pageNo },
    { headers }
  );
}

export function getSent(nickName, pageNo = 1, limit = 50) {
  const headers = {
    authentication: localStorage.getItem("access-token") || "",
  };
  return axios.post(
    `/ledgermail/sent`,
    { nickName, limit, pageNo },
    { headers }
  );
}

export function getArchive(nickName, pageNo = 1, limit = 50) {
  const headers = {
    authentication: localStorage.getItem("access-token") || "",
  };
  return axios.post(
    `/ledgermail/archive`,
    { nickName, limit, pageNo },
    { headers }
  );
}

export function getSingleFolder(nickName, pageNo = 1, limit = 50, tagName) {
  const headers = {
    authentication: localStorage.getItem("access-token") || "",
  };
  return axios.post(
    `/ledgermail/user-folder`,
    { nickName, limit, pageNo, tagName },
    { headers }
  );
}

export function getSingleInboxMail(nickName, parentId) {
  const headers = {
    authentication: localStorage.getItem("access-token") || "",
  };

  return axios.post(
    `/ledgermail/single-inbox-mail`,
    { nickName, parentId },
    { headers }
  );
}

export function getReplyMails(nickName, id, parentId) {
  const headers = {
    authentication: localStorage.getItem("access-token") || "",
  };

  return axios.post(
    `/ledgermail/get-child-mails`,
    { nickName, id, parentId },
    { headers }
  );
}

export function editUserProfile(userData) {
  const headers = {
    authentication: localStorage.getItem("access-token") || "",
  };
  const { firstName, lastName, nickName, emailId } = userData;
  return axios.post(
    `/ledgermail/change-name`,
    { firstName, lastName, nickName, emailId },
    { headers }
  );
}

export function autoFetch(userData) {
  const headers = {
    authentication: localStorage.getItem("access-token") || "",
  };
  const { nickName, regex } = userData;
  return axios.post(`/ledgermail/auto-fetch`, { nickName, regex }, { headers });
}

export function getFilteredInbox(
  nickName,
  selectedNames = [],
  pageNo = 1,
  limit = 50
) {
  const headers = {
    authentication: localStorage.getItem("access-token") || "",
  };
  return axios.post(
    `/ledgermail/filter-inbox`,
    { nickName, selectedNames, limit, pageNo },
    { headers }
  );
}

export function getFilteredSent(
  nickName,
  selectedNames = [],
  pageNo = 1,
  limit = 50
) {
  const headers = {
    authentication: localStorage.getItem("access-token") || "",
  };
  return axios.post(
    `/ledgermail/filter-sent`,
    { nickName, selectedNames, limit, pageNo },
    { headers }
  );
}

export function getFilteredArchive(
  nickName,
  selectedNames = [],
  pageNo = 1,
  limit = 50
) {
  const headers = {
    authentication: localStorage.getItem("access-token") || "",
  };
  return axios.post(
    `/ledgermail/filter-archive`,
    { nickName, selectedNames, limit, pageNo },
    { headers }
  );
}
