import { getUserDetails } from "@/plugins/axios.js";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function firstN_NameWithoutName(obj, n) {
  return Object.keys(obj)
    .sort()
    .filter((e) => !obj[e].firstName)
    .slice(0, n);
}

const state = {
  localMailSearch: "",
  userNames: {},
};

const mutations = {
  SET_USR_NAME: (state, data) => (state.userNames = data),
  SET_INITIAL_LOADING_STATUS: (state, status) => {
    state.initialLoading = typeof status == "boolean" ? status : false;
  },

  LOCAL_MAIL_SEARCH: (state, value) => {
    state.localMailSearch = value;
  },
};

const getters = {
  initialLoading: (state) => state.initialLoading,
  userNames: (state) => state.userNames,
  localMailSearch: (state) => state.localMailSearch,
};

const actions = {
  CHECK_USER_NAME: async ({ commit, dispatch }) => {
    const cookieString = getCookie("_lm_us_ck");
    if (!cookieString.length) return;

    const temp = await dispatch("DATA_DECRYPT", {
      cipherText: cookieString,
      secret: "this.hash#randomString+*$lm",
    });

    const userDetails = JSON.parse(temp || "{}");

    const fetchData = firstN_NameWithoutName(userDetails, 50);

    try {
      if (!fetchData.length) return commit("SET_USR_NAME", userDetails);
      const { data } = await getUserDetails(fetchData);
      if (!data) throw new Error("Server not reachable");
      if (data.error) throw new Error(data);

      for (let index = 0; index < Object.keys(data.userData).length; index++) {
        userDetails[Object.keys(data.userData)[index]] =
          data.userData[Object.keys(data.userData)[index]];
      }
    } catch (error) {
      console.error(error);
    }

    commit("SET_USR_NAME", userDetails);

    const cookieData = await dispatch("DATA_ENCRYPT", {
      message: JSON.stringify(userDetails),
      secret: "this.hash#randomString+*$lm",
    });

    if (process.env.NODE_ENV == "development")
      window.document.cookie = `_lm_us_ck=${cookieData}; max-age=604800; path=/;`;
    else
      window.document.cookie = `_lm_us_ck=${cookieData}; max-age=604800; path=/; domain=.${window.document.location.hostname}`;
  },
  STORE_USER_NAMES: async ({ getters, dispatch }, mailsArray) => {
    let AllUsers = getters.userNames;

    for (let index = 0; index < mailsArray.length; index++) {
      const element = mailsArray[index];

      if (!AllUsers[element.sender])
        AllUsers[element.sender] = { hashtagName: element.sender };
      element.receiver.forEach((e) => {
        if (!AllUsers[e]) AllUsers[e] = { hashtagName: e };
      });
      element.cc.forEach((e) => {
        if (!AllUsers[e]) AllUsers[e] = { hashtagName: e };
      });
    }

    const cookieData = await dispatch("DATA_ENCRYPT", {
      message: JSON.stringify(AllUsers),
      secret: "this.hash#randomString+*$lm",
    });

    if (process.env.NODE_ENV == "development")
      window.document.cookie = `_lm_us_ck=${cookieData}; max-age=604800; path=/;`;
    else
      window.document.cookie = `_lm_us_ck=${cookieData}; max-age=604800; path=/; domain=.${window.document.location.hostname}`;

    dispatch("CHECK_USER_NAME");
  },

  setLocalMailSearch: ({ commit, state }, newValue) => {
    commit("LOCAL_MAIL_SEARCH", newValue);
    return state.localMailSearch;
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
