import {
  autoFetch,
  getInbox,
  getSent, getSingleInboxMail
} from "@/plugins/axios.js";
import { EventBus } from "@/plugins/event-bus";
import { debounce } from "debounce";

const state = {
  autoFetchData: [],
};
const mutations = {
  ADD_FETCHED_DATA: (state, data) => {
    const newArr = [...state.autoFetchData, ...data];
    state.autoFetchData = Array.from(new Set(newArr));
  },
};


const playNotificationSound = debounce(function () {
  const audio = new Audio('/notification/sound-1.mp3');
  audio.play();
}, 500);

const getters = {
  autoFetch: (state) => state.autoFetchData,
};

// let fetchMailTimer;
let autoFetchTimer;

const actions = {
  CALL_AUTO_UPDATE: async ({ getters, commit }, regex) => {
    await autoFetch({
      nickName: getters.userInfo.nickName,
      regex: regex,
    })
      .then(({ data }) => {
        commit("ADD_FETCHED_DATA", data.nickNames);
      })
      .catch((err) => {
        console.error(err);
      });
  },
  AUTO_FETCH: ({ dispatch }, data) => {
    clearTimeout(autoFetchTimer);
    if (data.length < 3) return;
    autoFetchTimer = setTimeout(() => {
      dispatch("CALL_AUTO_UPDATE", data);
    }, 300);
  },
  NEW_INBOX: ({ dispatch }, data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      // (async () => {
      if (data.mailType == "replyMail") {
        await dispatch("FETCH_REPLY_INBOX_MAIL", data.parentId);
      } else {
        await dispatch("FETCH_NEW_INBOX_MAILS");
      }
      playNotificationSound()
      resolve();
      // })();
    });
  },
  FETCH_REPLY_INBOX_MAIL: async ({ getters, commit }, parentId) => {
    const newData = new Promise((resolve) => {
      getSingleInboxMail(getters.userInfo.nickName, parentId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Fetching new mails" + error);
        });
    });

    newData.then((data) => {
      const index = getters.getInbox.findIndex(
        (mail) => mail.parentId == parentId
      );

      commit("NEW_REPLY_SOCKET", {
        mail: data.mails[0],
        index,
      });
      EventBus.$emit("inboxUpdated", data);
      return;
    });
  },
  FETCH_NEW_INBOX_MAILS: async ({ getters, commit }) => {
    return new Promise((resolve) => {
      const newData = new Promise((resolve) => {
        getInbox(getters.userInfo.nickName, 1, 50)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            console.error("Fetching new mails" + error);
          });
      });

      newData.then((data) => {
        const tDiff = data.totalCount[0].count - getters.getInboxTotalMails;
        if (getters.getInboxTotalMails == 0 || tDiff > 49) {
          commit("INIT_INBOX_MAIL", { data });
          resolve();
        } else if (tDiff > 0) {
          const index = data.mails.findIndex(
            (mail) => mail.parentId == getters.getInbox[0].parentId
          );
          if (index > -1) data.mails.splice(index);

          commit("NEW_INBOX_SOCKET", {
            newMails: data.mails,
            totalCount: data.totalCount[0].count,
          });
          EventBus.$emit("inboxUpdated", data);
          resolve();
        }
      });
    });
  },

  NEW_SENT_MAILS: ({ getters, commit }) => {
    return new Promise((resolve, reject) => {
      getSent(getters.userInfo.nickName, 1, 50)
        .then(({ data }) => {
          commit("INIT_SENT_MAIL", { data });
          resolve();
        })
        .catch((error) => {
          commit("INIT_MAIL_ERROR");
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          )
            getters.vue.$toast.error(error.response.data.error);
          reject(error);
        });
    });
  },
};

export default { actions, state, mutations, getters };
