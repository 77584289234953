import {
  login,
  verifyEmail,
  getHashTagDetails,
  getSignature,
  verifyLogin,
  editUserProfile,
} from "../../plugins/axios";
import {
  ASEDecrypt,
  ASEEncrypt,
  getPublicKey,
  getExtPublicKey,
  getPrivateKey,
} from "../../plugins/ledgermail-jslib";

import * as userApi from "../../plugins/api/user-api";

import router from "@/router/index";

function getUserDetailsFromLocalStorage() {
  let authToken = localStorage.getItem("auth-token");
  if (!authToken) return {};
  authToken = JSON.parse(authToken);

  const userDetailsToStore = ASEDecrypt(
    localStorage.getItem("user-details"),
    `${authToken.emailId}oo$#`
  );

  return JSON.parse(userDetailsToStore);
}

const state = {
  status: "pending",
  token: localStorage.getItem("access-token") || "",
  user: getUserDetailsFromLocalStorage(),
  hashtagData: {},
  passcodeDialog: true,

  showPassCodeDialog: false,
  openPasscodeLogin: false,
};

const mutations = {
  UPDATE_USER_DETAILS: (state, details) => {
    const userDetailsToStore = ASEEncrypt(
      JSON.stringify(details),
      `${details.emailId}oo$#`
    );

    state.user = details;
    localStorage.setItem("user-details", userDetailsToStore);
  },
  SHOW_PASSCODE_DIALOG: (state, value) => {
    state.openPasscodeLogin = value;
  },
  SHOW_SET_PASSCODE_DIALOG: (state, value) => {
    state.showPassCodeDialog = value;
  },

  SET_AUTH_STATUS: (state, status) => {
    state.status = status;
  },

  OPEN_PASSCODE: (state, dialogStatus) => {
    state.passcodeDialog = dialogStatus;
  },
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  accessToken: (state) => state.token,
  authStatus: (state) => state.status,
  userInfo: (state) => state.user,
  mnemonic: (state) => state.user.mnemonic,
  passcodeDialogStatus: (state) => state.passcodeDialog,

  openPasscodeLogin: (state) => state.openPasscodeLogin,
  showPassCodeDialog: (state) => state.showPassCodeDialog,
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

const actions = {
  AUTH_LOGIN: async ({ commit, dispatch }, { mnemonic, nickName }) => {
    commit("SET_AUTH_STATUS", "loading");
    const publicKey = await getExtPublicKey(mnemonic);

    return new Promise((resolve, reject) => {
      try {
        const requestTime = new Date();

        login(publicKey, nickName).then(({ data }) => {
          if (data.error) {
            dispatch("AUTH_LOGOUT");
            reject(data.message);
          } else {
            commit("SET_AUTH_STATUS", "success");

            const userFolders = data.user.folder ? data.user.folder : {};
            data.user.mnemonic = mnemonic;

            dispatch("GET_PRIVATEKEY", mnemonic);

            if (data.tokenExpire == "1d") {
              // requestTime.setDate(requestTime.getDate() + 1);
              requestTime.setSeconds(requestTime.getSeconds() + 30);
            }

            localStorage.setItem(
              "auth-token",
              JSON.stringify({
                token: data.token,
                exp: requestTime,
                emailId: data.user.emailId,
              })
            );

            localStorage.setItem("folders-list", JSON.stringify(userFolders));
            localStorage.setItem("access-token", data.token);

            dispatch("INITIALIZE_EMPTY_FOLDERS", userFolders);

            commit("UPDATE_USER_DETAILS", data.user);

            commit("SET_FOLDERS_LIST", userFolders);
            resolve();
          }
        });
      } catch (error) {
        dispatch("AUTH_LOGOUT");
        commit("SET_AUTH_STATUS", "error");
        reject(error);
      }
    });
  },
  CREATE_USER: async (
    { commit },
    { firstName, lastName, emailId, mnemonic, img, nickName, role }
  ) => {
    commit("SET_AUTH_STATUS", "loading");
    if (nickName[0] != "#") nickName = "#" + nickName;

    const publicKey = await getPublicKey(mnemonic);
    const extPublicKey = await getExtPublicKey(mnemonic);
    const privateKey = await getPrivateKey(mnemonic);

    return new Promise((resolve, reject) => {
      try {
        getSignature(
          "CREATE_USER",
          { firstName, lastName, publicKey, extPublicKey },
          emailId,
          img,
          nickName,
          role
        )
          .then(({ data }) => {
            if (data.error) reject(data.message);

            userApi
              .createUser(
                privateKey,
                {
                  firstName,
                  lastName,
                  emailId,
                  img,
                  publicKey,
                  extPublicKey,
                  nickName,
                  role,
                  created: data.created,
                },
                data.signature
              )
              .then((result) => {
                commit("SET_AUTH_STATUS", "pending");
                if (result.success) resolve();
                else {
                  console.error("[Create user] ERROR: ", result.response);
                  reject(result.message);
                }
              });
          })
          .catch((error) => {
            console.error("[Create user] ERROR: ", error);
            commit("SET_AUTH_STATUS", "pending");
            reject(error);
          });
      } catch (error) {
        commit("SET_AUTH_STATUS", "pending");
        reject(error);
      }
    });
  },
  AUTH_INIT: async ({ getters, dispatch }) => {
    const userDetails = JSON.parse(getCookie("_mh_usr") || "{}");

    if (!userDetails || !userDetails.email)
      return dispatch("AUTH_LOGOUT").then(() => {
        router.push("/auth/signup");
      });

    if (!getters.isAuthenticated)
      return dispatch("AUTH_LOGOUT").then(() => {
        router.push("/auth/signup");
      });

    await verifyLogin(getters.userInfo.nickName)
      .then(({ data }) => {
        if (data.logout || data.publicKey != getters.userInfo.publicKey) {
          getters.vue.$toast.error("Session expired!");
          dispatch("SESSION_EXPIRED");
        } else {
          dispatch("INITIALIZE_EMPTY_FOLDERS");
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch("SESSION_EXPIRED");
        getters.vue.$toast.error("Session expired!");

        return false;
      });

    return true;
  },

  VERIFY_EMAIL: async ({ commit }, emailId) => {
    const isUser = (await verifyEmail(emailId)).data;

    if (isUser.exist) return router.push("/auth/login");

    const hashtagResponse = (await getHashTagDetails([emailId])).data;

    return new Promise((resolve, reject) => {
      if (!hashtagResponse || hashtagResponse.error) {
        console.error(hashtagResponse);
        commit("SET_AUTH_STATUS", "pending");
        getters.vue.$toast.error(hashtagResponse.error.message);
      } else if (!hashtagResponse.result[emailId]) {
        commit("SET_AUTH_STATUS", "pending");
        reject("Invalid Email, Please re-login in hashtag.space to continue");
        // } else if (!hashtagResponse.result[emailId].isDomain) {
        //   commit("SET_AUTH_STATUS", "pending");
        //   reject(
        //     "Invalid Email, Only users who has domain under Hashtag.space are allowed!"
        //   );
      } else if (!hashtagResponse.result[emailId].nickName) {
        commit("SET_AUTH_STATUS", "pending");
        reject("Invalid Email, Please set your Hashtag name!");
      } else resolve({ ...isUser, userData: hashtagResponse.result[emailId] });
    });
  },

  SESSION_EXPIRED: ({ commit }) => {
    let passcodeInfo = localStorage.getItem("p_c");
    if (passcodeInfo && passcodeInfo.length)
      return commit("SHOW_PASSCODE_DIALOG", true);

    commit("SET_AUTH_STATUS", "pending");
    commit("RESET_MAIL_STAGE");

    localStorage.removeItem("user-details");
    localStorage.removeItem("access-token");
    localStorage.removeItem("auth-key");
    localStorage.removeItem("folders-list");
    router.push("/auth/login");
  },

  AUTH_LOGOUT: ({ commit }, details = {}) => {
    if (details.removeCookies) {
      const hostname = window.document.location.hostname.split(".");
      const domain =
        hostname.length < 3
          ? window.document.location.hostname
          : hostname.slice(-2).join(".");
      window.document.cookie = `_mh_usr=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=.${domain};`;
      window.document.cookie = `_mh_usr=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
    return new Promise((resolve) => {
      const passocde = localStorage.getItem("p_c");
      if (passocde && passocde.length)
        localStorage.removeItem("disable_passcode");

      commit("SET_AUTH_STATUS", "pending");
      commit("RESET_MAIL_STAGE");
      commit("SHOWING_AUTH_PAGE_STATUS", true);

      localStorage.removeItem("user-details");
      localStorage.removeItem("p_c");

      localStorage.removeItem("auth-token");
      localStorage.removeItem("access-token");
      localStorage.removeItem("auth-key");
      localStorage.removeItem("folders-list");
      resolve();
    });
  },

  EDIT_USER: async ({ getters, commit }, userData) => {
    commit("DUMMY", true);

    // GLB_VUE.$toast("asd");

    getters.vue.$toast.success("asd");
    return new Promise((resolve, reject) => {
      editUserProfile(userData)
        .then(({ data }) => {
          if (!data.error) {
            let mnemonic = getters.userInfo.mnemonic;

            commit("UPDATE_USER_DETAILS", { ...data.userData, mnemonic });
            // dispatch('MAIL_INIT', { emailId: userData.emailId });

            resolve(data.userData);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          if (error.response.data.error) reject(error.response.data.error);
          else reject("Request time out: Please try again later!");
        });
    });
  },
  OPEN_PASSCODE_DIALOG: ({ commit }, dialogStatus) => {
    commit("OPEN_PASSCODE", dialogStatus);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
