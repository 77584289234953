// import axios from "@/plugins/axios.js";
import coreApi from "@/plugins/old-api/core-api.js";
import { getPrivateKey } from "@/plugins/ledgermail-jslib";

import { ObjectID } from "bson";

const state = {
  folders: JSON.parse(localStorage.getItem("folders-list")) || {},
};
const mutations = {
  SET_FOLDERS_LIST: (state, folders) => {
    state.folders = folders;
  },
};

const getters = { foldersList: (state) => state.folders };

const actions = {
  CREATE_FOLDER: async ({ commit, getters, dispatch }, { folderName }) => {
    const folderTag = new ObjectID().toString();

    const privateKey = await getPrivateKey(getters.userInfo.mnemonic);

    return new Promise((resolve, reject) => {
      coreApi
        .createFolder(privateKey, {
          nickName: getters.userInfo.nickName,
          folderName,
          folderTag,
        })
        .then((result) => {
          if (result.success) {
            var folders = JSON.parse(localStorage.getItem("folders-list"));
            folders[folderTag] = folderName;
            localStorage.setItem("folders-list", JSON.stringify(folders));
            commit("SET_FOLDERS_LIST", folders);
            dispatch("INITIALIZE_EMPTY_FOLDERS");
            resolve(result);
          } else
            reject(
              result.message
                ? result.message
                : "Something went wrong, try again later!"
            );
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          reject(
            "Error 404: Please check your connection and try again later!"
          );
        });
    });
  },
  RENAME_FOLDER: async (
    { commit, getters, dispatch },
    { folderName, folderTag }
  ) => {
    const privateKey = await getPrivateKey(getters.userInfo.mnemonic);
    return new Promise((resolve, reject) => {
      coreApi
        .renameFolder(privateKey, {
          nickName: getters.userInfo.nickName,
          folderName,
          folderTag,
        })
        .then((result) => {
          if (result.success) {
            var folders = JSON.parse(localStorage.getItem("folders-list"));
            folders[folderTag] = folderName;
            localStorage.setItem("folders-list", JSON.stringify(folders));
            commit("SET_FOLDERS_LIST", folders);
            dispatch("INITIALIZE_EMPTY_FOLDERS");
            resolve(result);
          } else reject(result.message);
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          reject(
            "Error 404: Please check your connection and try again later!"
          );
        });
    });
  },
  MOVE_FOLDER: async ({ getters }, { emailObjectId, folderTag }) => {
    const nickName = getters.userInfo.nickName;
    const privateKey = await getPrivateKey(getters.userInfo.mnemonic);
    return new Promise((resolve, reject) => {
      coreApi
        .moveToFolder(privateKey, { nickName, emailObjectId, folderTag })
        .then((result) => {
          if (result.success) {
            resolve(result);
          } else reject(result.message);
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          reject(
            "Error 404: Please check your connection and try again later!"
          );
        });
    });
  },
  DELETE_FOLDER: async ({ commit, getters, dispatch }, folderTag) => {
    const privateKey = await getPrivateKey(getters.userInfo.mnemonic);

    return new Promise((resolve, reject) => {
      coreApi
        .deleteFolder(privateKey, {
          nickName: getters.userInfo.nickName,
          folderTag,
        })
        .then((result) => {
          if (result.success) {
            var folders = JSON.parse(localStorage.getItem("folders-list"));
            delete folders[folderTag];
            localStorage.setItem("folders-list", JSON.stringify(folders));
            commit("SET_FOLDERS_LIST", folders);
            dispatch("INITIALIZE_EMPTY_FOLDERS");
            resolve(result);
          } else reject(result.message);
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          reject(
            "Error 404: Please check your connection and try again later!"
          );
        });
    });
  },
};

export default { state, mutations, getters, actions };
