const request = require("request");
const ledgermail_v2 = require("../ledgermail-jslib");
const {
  getReqOptions,
  getProcessedBroadcastResponse,
} = require("./core-api-helper");

// export default {
export async function createUser(privateKey, data, orgSignature) {
  const txBody = Buffer.from(
    JSON.stringify({
      emailId: data.emailId,
      firstName: data.firstName,
      lastName: data.lastName,
      publicKey: data.publicKey,
      extPublicKey: data.extPublicKey,
      img: data.img,
      nickName: data.nickName,
      role: data.role,
      created: data.created,
    })
  ).toString("base64");
  const signature = await ledgermail_v2.verifyTransaction(txBody, privateKey);

  return new Promise((resolve, reject) => {
    request(
      getReqOptions("CREATE_USER", txBody, signature, orgSignature),
      (error, response, body) => {
        if (!error) resolve(getProcessedBroadcastResponse(body));
        reject({ message: "Error #501, Try again after some time!" });
      }
    );
  });
}

export async function addDomains(privateKey, data) {
  const txBody = Buffer.from(
    JSON.stringify({
      nickName: data.nickName,
      newDomains: data.newDomains,
      expiredDomains: data.expiredDomains,
    })
  ).toString("base64");
  const signature = await ledgermail_v2.verifyTransaction(txBody, privateKey);

  return new Promise((resolve, reject) => {
    request(
      getReqOptions("UPDATE_USER_DOMAIN", txBody, signature),
      (error, response, body) => {
        if (!error) resolve(getProcessedBroadcastResponse(body));
        reject({ message: "Error #501, Try again after some time!" });
      }
    );
  });
}

export async function blockHashtagNames(privateKey, data) {
  const txBody = Buffer.from(
    JSON.stringify({
      nickName: data.nickName,
      hashtagName: data.hashtagName,
      operation: "BLOCK_USER",
    })
  ).toString("base64");
  const signature = await ledgermail_v2.verifyTransaction(txBody, privateKey);

  return new Promise((resolve, reject) => {
    request(
      getReqOptions("UPDATE_BLOCKED_NAMES", txBody, signature),
      (error, response, body) => {
        if (!error) resolve(getProcessedBroadcastResponse(body));
        reject({ message: "Error #501, Try again after some time!" });
      }
    );
  });
}

export async function unblockHashtagNames(privateKey, data) {
  const txBody = Buffer.from(
    JSON.stringify({
      nickName: data.nickName,
      hashtagName: data.hashtagName,
      operation: "UNBLOCK_USER",
    })
  ).toString("base64");
  const signature = await ledgermail_v2.verifyTransaction(txBody, privateKey);

  return new Promise((resolve, reject) => {
    request(
      getReqOptions("UPDATE_BLOCKED_NAMES", txBody, signature),
      (error, response, body) => {
        if (!error) resolve(getProcessedBroadcastResponse(body));
        reject({ message: "Error #501, Try again after some time!" });
      }
    );
  });
}

// };
