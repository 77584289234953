import { getPublicKeys } from "@/plugins/axios.js";
import {
  decrypt, getExtPrivateKey, getPrivateKey
} from "@/plugins/ledgermail-jslib";
const CryptoJS = require("crypto-js");

const state = {
  extPrivateKey: "",
  privateKey: "",
};

const mutations = {
  SET_EXT_PRIVATE_KEY: (state, data) => {
    state.extPrivateKey = data;
  },
  SET_PRIVATE_KEY: (state, data) => {
    state.privateKey = data;
  },
};

const getters = {
  extPrivateKey: (state) => state.extPrivateKey,
  privateKey: (state) => state.privateKey,
};

const actions = {
  GET_PRIVATEKEY: async ({ getters, commit }, mnemonic) => {
    mnemonic = mnemonic ? mnemonic : getters.mnemonic;

    const extPrivateKey = await getExtPrivateKey(getters.mnemonic);
    commit("SET_EXT_PRIVATE_KEY", extPrivateKey);

    const privateKey = await getPrivateKey(mnemonic);
    commit("SET_PRIVATE_KEY", privateKey);
  },
  DECRYPT_MAIL: async ({ getters, commit, dispatch }, mail) => {
    if (mail.isDecrypt) return;
    else if (!JSON.parse(mail.secrets)[getters.userInfo.nickName]) return;
    mail.isDecrypt = false;

    const randObj = JSON.parse(mail.secrets)[getters.userInfo.nickName];

    let extPrivateKey = getters.extPrivateKey;
    if (!extPrivateKey.length) {
      extPrivateKey = await getExtPrivateKey(getters.mnemonic);
      commit("SET_PRIVATE_KEY", extPrivateKey);
    }

    mail.randomString = await decrypt(typeof randObj === 'object' ? randObj : JSON.parse(randObj), extPrivateKey);

    mail.body = await dispatch("DATA_DECRYPT", {
      cipherText: mail.body,
      secret: mail.randomString,
    });

    mail.forwardMails.forEach(async (fMail) => {
      fMail.body = await dispatch("DATA_DECRYPT", {
        cipherText: fMail.body,
        secret: mail.randomString,
      });
    });

    return new Promise((resolve) => {
      mail.isDecrypt = true;
      resolve();
    });
  },
  DECRYPT_REPLY_MAILS: async ({ dispatch }, { mails, randomString }) => {
    if (!mails) return;
    for (let index = 0; index < mails.length; index++) {
      const mail = mails[index];
      if (mail.isDecrypt) return;
      mail.isDecrypt = false;

      mails[index].body = await dispatch("DATA_DECRYPT", {
        cipherText: mail.body,
        secret: randomString,
      });

      mails[index].forwardMails.forEach(async (fMail) => {
        fMail.body = await dispatch("DATA_DECRYPT", {
          cipherText: fMail.body,
          secret: randomString,
        });
      });
      mail.isDecrypt = true;
    }

    return new Promise((resolve) => {
      resolve();
    });
  },

  GET_PUBIC_KEYS: async ({ commit, getters }, emailIds) => {
    commit("DUMMY", true);
    emailIds = Array.from(new Set([...emailIds, getters.userInfo.nickName]));

    return new Promise((resolve, reject) => {
      getPublicKeys(emailIds)
        .then(async ({ data }) => {
          var mappedNames = Object.keys(data.publicKeys);
          mappedNames = mappedNames.map((da) => da.toLowerCase());
          var cmp = Array.from(new Set(emailIds));
          cmp = cmp.map((da) => da.toLowerCase());

          if (mappedNames.length >= cmp.length) resolve(data.publicKeys);
          else {
            const nonExistMails = await cmp.filter(function (n) {
              return mappedNames.indexOf(n) == -1;
            });
            alert(
              "The following recipient(s) are not part of Hashtag.space Mail! \n" +
              nonExistMails.toString()
            );
            reject(false);
          }
        })
        .catch((error) => {
          console.error("error", error);

          reject();
        });
    });
  },

  DATA_ENCRYPT: ({ commit }, { message, secret }) => {
    commit("DUMMY");
    return new Promise((resolve) => {
      resolve(CryptoJS.AES.encrypt(message, secret).toString());
    });
  },
  DATA_DECRYPT: ({ commit }, { cipherText, secret }) => {
    commit("DUMMY");
    return new Promise((resolve) => {
      var bytes = CryptoJS.AES.decrypt(cipherText, secret);
      resolve(bytes.toString(CryptoJS.enc.Utf8));
    });
  },
};

export default { actions, state, mutations, getters };
