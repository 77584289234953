<template>
    <v-app
        :id="$store.getters.theme === 'dark' ? 'scroll-dark' : 'scroll-light'"
        :dark="$store.getters.theme === 'dark' ? true : false"
    >
        <router-view
            :class="$store.getters.theme === 'dark' ? 'glb-dark' : 'glb-white'"
        />
        <v-footer app :dark="$store.getters.theme === 'dark' ? true : false">
            <small class="mx-auto">
                Powered by - Ledgermail &lt;
                <a href="http://ledgermail.io" target="_blank">ledgermail.io</a>
                &gt;
            </small>
        </v-footer>
    </v-app>
</template>

<script>
export default {
    name: "App",

    data: () => ({}),

    created() {
        this.$store.dispatch("GLOBAL_FUN", this);
    },
};
</script>

<style lang="scss">
html {
    overflow: auto !important;
}

#scroll-dark {
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #000;
        border-radius: 10px;
    }

    ::-webkit-scrollbar {
        width: 12px;
        background-color: #000;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        // background-image: -webkit-gradient(
        //     linear,
        //     left bottom,
        //     left top,
        //     color-stop(0.44, rgb(122, 153, 217)),
        //     color-stop(0.72, rgb(73, 125, 189)),
        //     color-stop(0.86, rgb(28, 58, 148))
        // );
        background: linear-gradient(135deg, #873f99, #311438);
    }
}
#scroll-light {
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
    }

    ::-webkit-scrollbar {
        width: 12px;
        background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: linear-gradient(180deg, #b7eaf6, #777fc4);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        // background-color: #555;
    }
}

body,
.v-application {
    font-family: "Montserrat", sans-serif !important;
}

h2 {
    font-size: 32px;
}

.ptr {
    cursor: pointer;
}

.main-color {
    color: #ff9800 !important;
}

.block-radios {
    border-radius: 10px;
}

.glb-dark {
    color: white !important;

    .main-btn {
        background: linear-gradient(rgb(135, 63, 153), rgb(49, 20, 56));
    }

    .glb-border-top {
        border-top: 2px solid rgb(2, 86, 157) !important;
    }
}

.glb-white {
    color: black !important;

    .main-btn {
        background: linear-gradient(rgb(183, 234, 246), rgb(119, 127, 196));
    }

    .glb-border-top {
        border-top: 2px solid rgb(0, 0, 0) !important;
    }
}

.max-text {
    width: 250px;
    max-width: 100%;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    overflow: hidden;
}
</style>