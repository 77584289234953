const state = {
  theme: "dark",
};

const mutations = {
  DARK_THEME: (state) => (state.theme = "dark"),
  LIGHT_THEME: (state) => (state.theme = "light"),
};

const getters = {
  theme: (state) => state.theme,
};

const actions = {};

export default {
  state,
  mutations,
  getters,
  actions,
};
