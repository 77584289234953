export default {
  path: "/",
  component: () => import("../../views/mail/Dashboard.vue"),
  redirect: "/mail/inbox",
  children: [
    {
      path: "/mail/inbox",
      component: () => import("../../views/mail/pages/Inbox.vue"),
      name: "inbox",
      meta: {
        title: "Inbox | LedgerMail",
      },
    },
    {
      path: "/mail/sent",
      component: () => import("../../views/mail/pages/Sent.vue"),
      name: "sent",
      meta: {
        title: "Sent | LedgerMail",
      },
    },
    {
      path: "/mail/archive",
      component: () => import("../../views/mail/pages/Archive.vue"),
      name: "archive",
      meta: {
        title: "Archive | LedgerMail",
      },
    },
    {
      path: "/mail/inbox/:id",
      component: () => import("../../views/mail/mail-view/MailView.vue"),
      name: "inbox",
      meta: {
        title: "Inbox | LedgerMail",
      },
    },
    {
      path: "/mail/sent/:id",
      component: () => import("../../views/mail/mail-view/MailView.vue"),
      name: "sent",
      meta: {
        title: "Sent | LedgerMail",
      },
    },
    {
      path: "/mail/archive/:id",
      component: () => import("../../views/mail/mail-view/MailView.vue"),
      name: "archive",
      meta: {
        title: "Archive | LedgerMail",
      },
    },
    {
      path: "/mail/folder/:tagName",
      component: () => import("../../views/mail/pages/Folders.vue"),
      // import("../../views/mail/folders/folder-view/folder-view.vue"),
      name: "user folders",
      meta: {
        title: "LedgerMail",
      },
    },
    {
      path: "/mail/folder/:tagName/:id",
      component: () => import("../../views/mail/mail-view/FolderMailView.vue"),
      name: "user folders",
      meta: {
        title: "Archive | LedgerMail",
      },
    },
    //   // {
    //   //   path: "/compose",
    //   //   component: () => import("../../views/mail/compose.vue"),
    //   //   meta: {
    //   //     title: "New Secured Mail | LedgerMail",
    //   //   },
    //   // },
  ],
  meta: {
    requiresAuth: true,
  },
};
