import { sendNotification } from "../axios";

const headers = {
  "Content-Type": "text/plain",
  Accept: "application/json-rpc",
};

export function getReqOptions(type, body, signature, orgSignature) {
  const reqData = { type, body, signature, orgSignature, now: Date.now() };
  const baseRequest = Buffer.from(JSON.stringify(reqData)).toString("base64");

  return {
    url: process.env.VUE_APP_BASE_URL_CORE,
    method: "POST",
    json: true,
    headers,
    body: {
      jsonrpc: "2.0",
      id: "ledgermail-user",
      method: "broadcast_tx_commit",
      params: { tx: baseRequest },
    },
  };
}

export function getProcessedBroadcastResponse(response, mailData = null) {
  const res = response.result;

  if (response.error) return { success: false, message: response.error.data };
  else if (res.check_tx.code === 0 && res.deliver_tx.code === 0) {
    if (mailData) sendNotification(mailData);
    return { success: true, response: res };
  } else
    return {
      success: false,
      response: res,
      message: res.check_tx.info || res.deliver_tx.info,
    };
}
