const state = {
  alerts: [],
  snackbar: false,
  snackMessage: "",
  snackType: "info",

  alertTimer: {},

  fromAuthPage: false,
};

function debounce(func, timeout = 5500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const mutations = {
  SHOWING_AUTH_PAGE_STATUS: (state, data) => {
    state.fromAuthPage = data;
  },

  NEW_ALERT: (state, data) => {
    state.alerts.push(data);
    setTimeout(() => {
      state.alerts.pop();
    }, 5000);

    // state.alerts.push(data);
    // state.alertTimer[state.alerts.length] = setTimeout(() => {
    //   clearTimeout(state.alertTimer[state.alerts.length]);
    //   state.alerts.pop();
    // }, 5000);
  },
  CLEAR_WITH_INDEX: (state, index) => {
    clearTimeout(state.alertTimer[index + 1]);
  },

  NEW_SNACK: (state, data) => {
    state.snackbar = true;
    state.snackMessage = data.message;
    state.snackType = data.type;
  },
  SNACKBAR_COMPLETE: (state, value) => {
    state.snackbar = value;
    state.snackMessage = "";
    state.snackType = "info";
  },
  REMOVE_ALERTS: (state) => {
    state.alerts = [];
    state.alertTimer = {};
  },
};

const getters = {
  getAlerts: (state) => state.alerts,
  snackbar: (state) => state.snackbar,
  snackMessage: (state) => state.snackMessage,
  snackType: (state) => state.snackType,

  fromAuthPage: (state) => state.fromAuthPage,
};

const actions = {
  GLOBAL_INIT: async ({ getters, commit, dispatch }) => {
    const storedToken = JSON.parse(localStorage.getItem("auth-token") || "{}");

    if (storedToken.exp) {
      commit("SHOWING_AUTH_PAGE_STATUS", false);

      if (new Date() < new Date(storedToken.exp)) {
        await dispatch("MAIL_INIT", { emailId: getters.userInfo.nickName });
      } else {
        await dispatch("AUTH_LOGIN", getters.userInfo);
        await dispatch("MAIL_INIT", { emailId: getters.userInfo.nickName });
      }
      setTimeout(async () => {
        dispatch("CHECK_USER_NAME");
        dispatch("VERIFY_USR_IMAGE");
        await dispatch("VERIFY_DOMAINS");
      }, 0);
    } else commit("SHOWING_AUTH_PAGE_STATUS", true);
  },
  SNACKBAR: ({ commit }, message) => {
    commit("SNACKBAR_COMPLETE", false);
    commit("NEW_SNACK", { message, type: "info" });
  },
  ALERT_SUCCESS: ({ commit, dispatch }, message) => {
    commit("NEW_ALERT", { message, type: "success" });
    dispatch("RESET_ALERTS");
  },
  ALERT_ERROR: ({ commit, dispatch }, message) => {
    commit("NEW_ALERT", { message, type: "error" });
    dispatch("RESET_ALERTS");
  },
  ALERT_WARNING: ({ commit, dispatch }, message) => {
    commit("NEW_ALERT", { message, type: "warning" });
    dispatch("RESET_ALERTS");
  },
  CLEAR_ALERT_WITH_INDEX: ({ commit, dispatch }, index) => {
    commit("CLEAR_WITH_INDEX", index);
    dispatch("RESET_ALERTS");
  },
  RESET_ALERTS: ({ commit }) => {
    debounce(() => commit("REMOVE_ALERTS"));
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
